<template>
  <div class="rs-home-pg__container">
    <div class="rs-home__heading">Shyam Prasad Mukherjee Rurban Mission</div>
    <div class="rs-home__cta-wrap">
      <div class="rs-home__cta-item" @click="$router.push({ name: 'SurveyForm' })" v-ripple>
        <i class="light-icon-plus"></i>
        Submit New Response
      </div>
      <div class="rs-home__cta-item" @click="$router.push({ name: 'Responses' })" v-ripple>
        <i class="light-icon-layout-list"></i>
        View All Responses
      </div>
    </div>
  </div>
</template>

<script>
import LvButton from 'lightvue/button';
export default {
  components: {
    LvButton,
  },
};
</script>

<style lang="scss">
.rs-home-pg__container {
  //   background-color: #133a56;
  padding: 4em 0 12em;
  //   height: 100vh;
}
.rs-home__heading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: teal;
  text-align: center;
  padding: 0px 16px;
}
.rs-home__cta-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  margin-top: 64px;

  .rs-home__cta-item {
    background-color: #ffffff;
    padding: 24px;
    height: 220px;
    width: 220px;
    margin: 12px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    text-align: center;
    cursor: pointer;

    font-size: 20px;
    color: rgba(0, 0, 0, 0.4);
    // color: #203d4a;
    user-select: none;
    border-radius: 4px;

    box-shadow: 16px 16px 32px #e8e8e8, -16px -16px 32px #fff;

    transition: all 0.3s ease-in-out;

    &:active,
    &:hover {
      color: teal;
      box-shadow: 16px 16px 32px #cbcbcb, -16px -16px 32px #fff;
    }
    .lv-ink {
      background-color: #63767e;
    }
    i {
      font-size: 48px;
      margin-bottom: 24px;
    }
  }
}
</style>
