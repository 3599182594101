<template>
  <div class="login__container">
    <br />
    <div class="form__header">
      <span>
        <img src="@main/assets/form-studio-only-logo.svg" alt="" class="brand-logo" />
      </span>
      <span>
        Rurban Survey
      </span>
    </div>
    <br />

    <div class="login__form">
      <lv-input :bottomBar="true" class="form__field" label="Name:" v-model="username" placeholder="John Smith" clearable> </lv-input>
      <!-- <lv-input class="form__field" :bottomBar="true" label="Email:" v-model="email" placeholder="abc@domain.com"></lv-input> -->
      <lv-dropdown :options="teamNumbers" v-model="selectedTeam" label="Team Number" :bottomBar="true" clearable></lv-dropdown>
      <lv-input class="form__field" :bottomBar="true" label="User ID:" v-model="userID" placeholder="abcxxx" clearable></lv-input>
      <div class="error__msg">
        {{ errorMsg }}
      </div>
      <br />
      <div class="cta__row">
        <lv-button class="login__btn" @click="login">Login</lv-button>
      </div>
      <br />
    </div>
  </div>
</template>

<script>
// import InputValue from '@/components/mixins/InputValue';
import LvInput from 'lightvue/input';
import LvDropdown from 'lightvue/dropdown';
import LvButton from 'lightvue/button';
import { loginIdList } from '@/utils/index';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { getSlug } from '../utils/slug';
export default {
  // mixins: [InputValue],

  components: {
    LvInput,
    LvDropdown,
    LvButton,
  },
  data() {
    return {
      uidList: loginIdList,
      email: '',
      username: '',
      userID: '',
      errorMsg: '',
      teamNumbers: ['Team 1', 'Team 2', 'Team 3', 'Team 4', 'Team 5', 'Team 6', 'Other'],
      selectedTeam: '',
    };
  },
  created() {
    let userRaw = localStorage.getItem('user');
    if (userRaw) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user && user.uid && loginIdList.indexOf(user.uid) !== -1) {
        this.$router.push('/home');
      }
    }
  },
  mounted() {
    // this.generateUID();
  },
  methods: {
    generateUID() {
      // I generate the UID from two parts here
      // to ensure the random number provide enough bits.
      for (let i = 1; i <= 100; i++) {
        var firstPart = (Math.random() * 46656) | 0;
        var secondPart = (Math.random() * 46656) | 0;
        firstPart = ('000' + firstPart.toString(36)).slice(-3);
        secondPart = ('000' + secondPart.toString(36)).slice(-3);
        this.uidList.push(firstPart + secondPart);
      }
    },

    async login() {
      //Valid User Id
      if (this.uidList.indexOf(this.userID) !== -1) {
        let userObj = {
          name: this.username,
          // email: this.email,
          team_number: this.selectedTeam,
          uid: this.userID,
          login_time: firebase.firestore.Timestamp.fromDate(new Date()).toDate(),
        };
        let docId = `${this.userID + '_' + getSlug(this.username)}`;
        window.localStorage.setItem('user', JSON.stringify(userObj));
        var db = firebase.firestore();
        const userDocRef = db.collection('users').doc(docId);
        const doc = await userDocRef.get();
        if (!doc.exists) {
          db.collection('users')
            .doc(docId)
            .set(userObj);
        }
        this.errorMsg = '';
        this.$router.push({ name: 'Home' });
      }
      //Invalid User Id
      else {
        this.errorMsg = 'Please enter valid User ID';
      }
    },
  },
};
</script>

<style lang="scss">
.login__container {
  max-width: 500px;
  margin: auto;
  margin-top: 4rem;
  padding: 1rem 4rem;
  border: 1px solid #dedede;
  border-radius: 4px;
  background-color: #ffffff;
  .form__header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 24px;
    font-weight: 500;
    color: rgb(39, 38, 38);
  }

  .login__form {
    margin-top: 26px;

    .form__field {
      margin: 12px 0px;
    }
    .cta__row {
      display: flex;
      justify-content: center;
      padding: 8px;

      .login__btn {
        width: 200px;
      }
    }
  }
}

.error__msg {
  color: rgb(253, 128, 106);
  font-size: 13px;
}
</style>
